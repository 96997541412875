export default {
  'Top.Title': '電力サポート中国',
  'Top.Message': 'こんにちは! { name }!',
  'ChangeLocale.Btn': '言語を切り替える',
  'ChangeLocale.Msg': '言語を英語に切り替えます。よろしいですか?',

  'title.company.name': '(株)ＢＯＵＧＯＫＡＮ',
// 共通
  'common.btn.send': '登録',
  'common.btn.close': '閉じる',
  'common.btn.back': '戻る',
  'common.btn.next': '次へ',
// ヘッダーメニュー
  'header.menu.mailupdate': 'メールアドレス変更',
  'header.menu.userinfo': 'ユーザー情報変更',
  'header.menu.help': 'ヘルプ',
  'header.menu.logout': 'ログアウト',
// ログイン
  'login.title': 'ログイン',
  'login.card.title': '防護管取付サービス申込受付',
  'login.in.mailaddress': 'メールアドレス',
  'login.in.username': 'ユーザー名(メールアドレス)',
  'login.in.password': 'パスワード',
  'login.btn.login': 'ログイン',
  'login.btn.logout': 'ログアウト',
  'login.btn.regist': '新規ユーザー登録',
  'login.btn.reissue': 'パスワードを忘れた方',
  'login.message.error': 'メールアドレスまたはパスワードに誤りがあります',
  'login.dialog.error.nouserid': '本登録が完了してません。本登録が完了しましたら再度アクセスをお願いします。',
  'login.dialog.error.input': '誤ったメールアドレスまたはパスワードが入力されました。再度入力してください。',
  'login.dialog.error.system': 'システムエラーが発生しました。',
// 写真添付(共通)
  'photo.upload.sample': '登録する写真の例は',
  'photo.upload.sample.here': 'こちら',
  'photo.upload.message.picture': '写真の登録が必要となります。予め現場で、取付け希望範囲の写真（複数枚）を撮影してください。',
  'photo.upload.message.require1': '・現場状況や取付け希望範囲がわかる写真※を登録してください。',
  'photo.upload.message.require2': '　（※写真上に、取付け希望範囲・箇所を具体的に書き込みいただけますと、取付希望範囲等を相互で確認する際にスムーズに行えます。）',
// 取付希望期間(共通)
  'term.fordays': '日間',
  'term.explanation': '取付けるまでの作業期間ではなく、防護管が必要な期間を入力してください。',

// ログイン(リダイレクト)
  'redirect.title': 'ログイン',

// パスワードリセット
  'passwordReset.message.forgetPassword': 'パスワードを忘れた方は、以下の情報を入力し、送信ください。',
  'passwordReset.title': 'パスワードリセット',
  'passwordReset.in.mailaddress': 'メールアドレス',
  'passwordReset.btn.doPasswordReset': '送信',
  'passwordReset.btn.backlogin': '戻る',
  'passwordReset.dialog.finish': '入力されたメールアドレスにメールを送付しました。メールに記載の認証コードと新しいパスワードを入力してください。',
  'passwordReset.dialog.error.input': 'メールアドレスが間違っている可能性があります。再度確認して入力してください。',
  //'passwordReset.dialog.error.expired': '認証コードの有効期限が切れています。再度パスワードリセットを実施してください。',
  'passwordReset.dialog.error.mailVerify': 'メールアドレスが検証されていないためメールが送信できませんでした。管理者にお問合せください。',
  'passwordReset.dialog.error.limitExceeded': '連続して実行されたため、一時的に処理が制限されています。少し時間をおいて再度実施をお願いします。',
  'passwordReset.dialog.error.system': 'システムエラーが発生しました。',
// パスワードリセット（確認コード）
  'passwordResetVerify.title': 'パスワードリセット確認',
  'passwordResetVerify.password': 'パスワード',
  'passwordResetVerify.password.confirm': 'パスワード(確認)',
  'passwordResetVerify.authenticationCode': '認証コード',
  'passwordResetVerify.btn.doPasswordReset': '送信',
  'passwordResetVerify.dialog.finish': 'パスワードリセットが完了しました。',
  'passwordResetVerify.dialog.error.input': '認証コードが違います。再度確認して入力してください。',
  'passwordResetVerify.dialog.error.expired': '認証コードの有効期限が切れています。再度パスワードリセットを実施してください。',
  'passwordResetVerify.dialog.error.system': 'システムエラーが発生しました。',
  'passwordResetVerify.dialog.error.misoperation': '予期せぬエラーが発生しました。再度パスワードリセットをやり直してください。',
// メールアドレス変更
  'mailUpdate.title': 'メールアドレス変更',
  'mailUpdate.in.mailaddress': 'メールアドレス',
  'mailUpdate.btn.doMailUpdate': '変更',
  'mailUpdate.btn.backPage': '戻る',
  'mailUpdate.dialog.finish': '入力されたメールアドレスにメールを送付しました。メールに記載の認証コードを入力してください。',
  'mailUpdate.dialog.error.input': '既に登録されているメールアドレスです。',
  'mailUpdate.dialog.error.input2': '現在登録されているメールアドレスです。再度確認して入力してください。',
  'mailUpdate.dialog.error.failure': 'メールアドレスの変更に失敗しました。再度やり直して下さい。',
  'mailUpdate.dialog.error.mail': 'メール送信に失敗しました。再度確認して入力してください。',
  'mailUpdate.dialog.error.system': 'システムエラーが発生しました。',
// メールアドレス変更（確認コード）
  'mailUpdateVerify.title': 'メールアドレス変更確認',
  'mailUpdateVerify.authenticationCode': '認証コード',
  'mailUpdateVerify.btn.doMailUpdateVerify': '変更',
  'mailUpdateVerify.dialog.finish': 'メールアドレスの変更が完了しました。再度ログインから実施してください。',
  'mailUpdateVerify.dialog.error.input': '認証コードが違います。再度確認して入力してください。',
  'mailUpdateVerify.dialog.error.expired': '認証コードの有効期限が切れています。防護管受付センターへお問合せください。',
  'mailUpdateVerify.dialog.error.exist': '既に登録されているメールアドレスです。再度やり直して下さい。',
  'mailUpdateVerify.dialog.error.failure': 'メールアドレスの変更に失敗しました。再度やり直して下さい。',
  'mailUpdateVerify.dialog.error.system': 'システムエラーが発生しました。',
// 新規ユーザー登録
  'userRegist.title': '新規ユーザー登録',
  'userRegist.mailaddress': 'メールアドレス',
  'userRegist.companynameKanji': '会社名(漢字)',
  'userRegist.companyname': '会社名(全角カナ)',
  'userRegist.usernameFamily': '申込者名(姓)(漢字)',
  'userRegist.usernameFirst': '申込者名(名)(漢字)',
  'userRegist.usernameFamilyKana': '申込者名(姓)(全角カナ)',
  'userRegist.usernameFirstKana': '申込者名(名)(全角カナ)',
  'userRegist.zipCode': '会社所在地(郵便番号)',
  'userRegist.companyaddress': '会社所在地(住所)',
  'userRegist.mobile': '携帯電話番号',
  'userRegist.telephone': '電話番号',
  'userRegist.password': 'パスワード',
  'userRegist.password2': 'パスワード(確認)',
  'userRegist.telephoneinfo': '※オペレータより電話連絡させていただく場合がありますので、平日の日中連絡の取れる連絡先をご記入ください',
  'userRegist.info':'この後の「取付申込」画面では、取付け希望範囲のわかる現場写真(複数枚)が必要になりますので、予め写真撮影をお願いします。',
  'userRegist.password.info': '※パスワードは半角英字大文字、半角英字小文字、半角数字を含めて8文字以上32文字以内で入力してください',
  'userRegist.btn.search': '検索',
  'userRegist.btn.confirm': '確認',
  'userRegist.btn.backPolicy': '戻る',
  'userRegist.dialog.error.nondata': '住所が取得できませんでした。',
  'userRegist.dialog.error.system': 'システムエラーが発生しました。',
// 新規ユーザー登録確認
  'userRegistConfirm.title': '新規ユーザー登録確認',
  'userRegistConfirm.mailaddress': 'メールアドレス',
  'userRegistConfirm.companynameKanji': '会社名(漢字)',
  'userRegistConfirm.companyname': '会社名(全角カナ)',
  'userRegistConfirm.usernameFamily': '申込者名(姓)(漢字)',
  'userRegistConfirm.usernameFirst': '申込者名(名)(漢字)',
  'userRegistConfirm.usernameFamilyKana': '申込者名(姓)(全角カナ)',
  'userRegistConfirm.usernameFirstKana': '申込者名(名)(全角カナ)',
  'userRegistConfirm.zipCode': '会社所在地(郵便番号)',
  'userRegistConfirm.companyaddress': '会社所在地(住所)',
  'userRegistConfirm.mobile': '携帯電話番号',
  'userRegistConfirm.telephone': '電話番号',
  'userRegistConfirm.btn.regist': '登録',
  'userRegistConfirm.btn.backUserRegist': '戻る',
  'userRegistConfirm.dialog.finish': '登録されたメールアドレスにメールを送付しました。メールに記載のURLをクリックして登録を完了させてください。',
  'userRegistConfirm.dialog.error.failure': 'ユーザー登録に失敗しました。再度やり直してください。',
  'userRegistConfirm.dialog.error.exist': '既に登録されているメールアドレスです。',
  'userRegistConfirm.dialog.error.mail': 'メールアドレスが間違っている可能性があります。再度確認して入力してください。',
  'userRegistConfirm.dialog.error.limitExceeded': '連続して実行されたため、一時的に処理が制限されています。少し時間をおいて再度実施をお願いします。',
  'userRegistConfirm.dialog.error.system': 'システムエラーが発生しました。',
// workContact
  'workContact.title': 'お問合せ・写真追加',
  'workHelp.title': 'ヘルプ',
  'workMenu.title': '取付申込メニュー',
  'workImportantMatter.title': '重要事項説明',
  'workInquiry.title': 'お問合せ・写真追加',
// workRequest
  'workRequest.title': '取付申込',
  'workRequest.title.subject': '件名(お客さま工事名)',
  'workRequest.select.prefecture': '現場所在地(県)',
  'workRequest.select.Tottori': '鳥取',
  'workRequest.select.Shimane': '島根',
  'workRequest.select.Okayama': '岡山',
  'workRequest.select.Hiroshima': '広島',
  'workRequest.select.Yamaguchi': '山口',
  'workRequest.select.Hyougo': '兵庫',
  'workRequest.select.Kagawa': '香川',
  'workRequest.select.Ehime': '愛媛',
  'workRequest.in.workaddress': '現場所在地(住所)',
  'workRequest.in.poleNumber': '現場所在地(電柱番号)',
  'workRequest.title.term': '取付希望期間 *',
  'workRequest.title.term.from': '取付希望開始日',
  'workRequest.title.term.to': '取付希望終了日',
  'workRequest.title.worktype': '工事・作業の分類 *',
  'workRequest.select.architecture': '建築',
  'workRequest.select.carry': '物品搬入',
  'workRequest.select.civilEngineering': '土木',
  'workRequest.select.road': '道路',
  'workRequest.select.wall': '外壁',
  'workRequest.select.paint': '塗装',
  'workRequest.select.workOther': 'その他',
  'workRequest.select.workOther.memo': 'その他記述欄',
  'workRequest.title.workContents': '工事・作業の内容 *',
  'workRequest.title.crane': 'クレーンの使用　',
  'workRequest.title.scaffold': '足場の設置　　　',
  'workRequest.select.yes': '有',
  'workRequest.select.no': '無',
  'workRequest.title.range': '防護管取付希望範囲 *(複数選択が可能)',
  'workRequest.check.distributionLine': '電線(配電線)',
  'workRequest.check.serviceLine': '電線(引込線)',
  'workRequest.check.machine': '機器',
  'workRequest.check.communicationLine': '通信線',
  'workRequest.check.rangeOther': 'その他',
  'workRequest.in.memo': 'ご連絡事項',
  'workRequest.title.pictureUpload': '写真アップロード',
  'workRequest.title.multiSelect': '（※1枚あたり5MBまで、複数登録できます）',
  'workRequest.message.contact': 'こちらの連絡先にオペレータから電話連絡いたします',
  'workRequest.message.orderNameNotes1-1': '件名(お客さま工事名)は、「御見積書」「御利用明細書」「請求書」等に表示します。',
  'workRequest.message.orderNameNotes1-2': 'なお、請求会社(三井住友カード)からの「請求書」には、先頭から１５文字(空白を含みます)までが印刷されます。',
  'workRequest.message.orderNameNotes2': '入力は全角でお願いします。',
  'workRequest.message.orderNameNotes3': '例）〇〇　〇〇様邸　新築工事　　　△△道路改良工事（〇〇工区）　　　〇〇ビル外壁塗装工事',
  'workRequest.dialog.finish': '取付申込の登録が完了しました。',
  'workRequest.dialog.error.failure': '取付申込の登録に失敗しました。',
  'workRequest.dialog.error.failure.resize': '不正な画像ファイルが含まれている可能性があります。アップロードしたファイルを確認してください。',
  'workRequest.dialog.error.failure.upload': '画像アップロードに失敗しました。お問合せ・写真追加画面から、画像をアップロードしてください。もしくは防護管受付センターまでご連絡ください。',
  'workRequest.dialog.error.system': 'システムエラーが発生しました。',
  'workRequest.dialog.inquiry': '取付申込を登録します。よろしいですか？',

  'workConsideration.title': '取付内容確認',
  'workConsiderationPay.title': '取付・お見積・ご請求金額内容確認',
  'workConsideration.title.latestImage': '最終(最新)取付イメージ',
  'workConsideration.btn.updates': '最新化',
  'workConsideration.message.free': 'こちらは申込時の自由記述欄です。',
  'workConsideration.schedule.construct': '取付予定日',
  'workConsideration.schedule.removal': '取外し予定日',
  'workConsideration.message.consent': '内容を確認して「承諾」をクリックしてください',
  'workConsideration.message.estimateConfirm': '内容を確認して「お見積金額確認」をクリックしてください',
  'workConsideration.message.billConfirm': '内容を確認して「ご請求金額確認」をクリックしてください',
  'workConsideration.message.removalConfirm': '内容を確認して「取外し確認」をクリックしてください',
  'workConsideration.btn.estimateConfirm': 'お見積金額確認',
  'workConsideration.btn.billConfirm': 'ご請求金額確認',
  'workConsideration.btn.consent': '承諾',
  'workConsideration.btn.inqualy.add': 'お問合せ・写真追加',
  'workConsideration.btn.confirm': '取外し確認',
  'workConsideration.in.workaddress': '現場所在地(住所)',
  'workConsideration.in.poleNumber': '現場所在地(電柱番号)',
  'workConsideration.title.term': '取付希望期間',
  'workConsideration.title.term.from': '取付希望開始日',
  'workConsideration.title.term.to': '取付希望終了日',
  'workConsideration.title.worktype': '工事・作業の分類',
  'workConsideration.select.architecture': '建築',
  'workConsideration.select.carry': '物品搬入',
  'workConsideration.select.civilEngineering': '土木',
  'workConsideration.select.road': '道路',
  'workConsideration.select.wall': '外壁',
  'workConsideration.select.paint': '塗装',
  'workConsideration.select.workOther': 'その他',
  'workConsideration.select.workOther.memo': 'その他記述欄',
  'workConsideration.select.yes': '有',
  'workConsideration.select.no': '無',
  'workConsideration.check.distributionLine': '電線(配電線)',
  'workConsideration.check.serviceLine': '電線(引込線)',
  'workConsideration.check.machine': '機器',
  'workConsideration.check.communicationLine': '通信線',
  'workConsideration.check.rangeOther': 'その他',
  'workConsideration.in.memo': 'ご連絡事項',
  'workConsideration.title.crane': 'クレーンの使用　',
  'workConsideration.title.scaffold': '足場の設置　　　',
  'workConsideration.select.prefecture': '現場所在地(県)',
  'workConsideration.title.range': '防護管取付希望範囲',
  'workConsideration.dialog.inquiry': 'この内容で取付を承ります。これ以降、取付内容の変更等が必要な場合は、お問合せ・写真追加画面からの連絡あるいは電話にて防護管受付センターまでご連絡ください。',
  'workConsideration.dialog.finish': '取付の承諾が完了しました。',
  'workConsideration.dialog.error.system': 'システムエラーが発生しました。',
  'workConsideration.message.nodata': '画像が登録されていません。',
  'workConsideration.dialog.error.failure': '取付の詳細情報取得処理に失敗しました。再度やり直してください。',
  'workConsideration.dialog.error.failure2': '取付の承諾処理に失敗しました。',

  'workEstimateConfirm.title': 'お見積金額確認',
  'workEstimateConfirm.dialog.inquiry': 'この内容で取付を承ります。これ以降、取付内容の変更等が必要な場合は、お問合せ・写真追加画面からの連絡あるいは電話にて防護管受付センターまでご連絡ください。なお、変更内容によっては、追加の費用が必要となる場合があります。',
  'workEstimateConfirm.dialog.finish': 'お見積内容の承諾を受付けました。',
  'workEstimateConfirm.dialog.error.estimateDLfailure': '見積書のダウンロードに失敗しました。',
  'workEstimateConfirm.dialog.error.consentfailure': 'お見積内容の承諾処理に失敗しました。',

  'workBillConfirm.title': 'ご請求額確認',
  'workBillConfirm.dialog.inquiry': 'この内容でご請求書を送付いたします。ご不明点等ございましたら、お問合せ・写真追加画面からの連絡あるいは電話にて防護管受付センターまでご連絡ください。',
  'workBillConfirm.dialog.finish': 'ご請求金額の確認を受付けました。',
  'workBillConfirm.dialog.error.billDLfailure': '利用明細書のダウンロードに失敗しました。',
  'workBillConfirm.dialog.error.consentfailure': 'ご請求金額の確認処理に失敗しました。',


  'workRemoval.message.after': '以降',
  'workRemoval.title': '取外し確認',
  'workRemoval.in.memo': 'ご連絡事項',
  'workRemoval.in.removaldate': '取外し予定日',
  'workRemoval.btn.confirm': '確認',
  'workRemoval.dialog.confirm': '取外し申込を受付けます。よろしいですか？',
  'workRemoval.dialog.finish': '取外し申込が完了しました。',
  'workRemoval.dialog.error.failure': '取外し申込処理に失敗しました。再度やり直してください。',
  'workRemoval.dialog.error.system': 'システムエラーが発生しました',

  'workInquiry.subject': '件名：',
  'workInquiry.upload.image': '写真アップロード',
  'workInquiry.upload.caution': '（1枚あたり5MBまで、複数登録できます）',
  'workInquiry.in.inqualy': 'お問合せ内容',
  'workInquiry.inquiry.history': 'お問合せ・回答　履歴',
  'workInquiry.dialog.inquiry': 'お問合せ内容を登録します。よろしいですか？',
  'workInquiry.dialog.finish': 'お問合せの登録が完了しました。',
  'workInquiry.dialog.error.failure': 'お問合せの登録に失敗しました。',
  'workInquiry.dialog.error.failure2': 'お問合せ履歴の参照に失敗しました。',
  'workInquiry.dialog.error.update': 'お問合せ履歴が更新されました。最新のお問合せ履歴を確認してからご登録ください。',
  'workInquiry.dialog.error.sizeover': 'お問合せ内容の文字数が制限に達しました。電話でお問合せください。写真の追加は可能です。',
  'workInquiry.dialog.error.system': 'システムエラーが発生しました。',
  'workInquiry.dialog.error.failure.resize': '不正な画像ファイルが含まれている可能性があります。アップロードしたファイルを確認してください。',
  'workInquiry.dialog.error.failure.upload': '画像アップロードに失敗しました。再度やり直してください。もしくは防護管受付センターオペレータまでご連絡ください。',

  'workmenu.info.title':'お知らせ',
  'workmenu.info.text':'　現在，一時的にメールアドレス変更機能が使用できない状態となっております。メールアドレスの変更を希望される方は，お手数ですが下記メールアドレスまでご連絡ください。',
  'workmenu.info.contact':'　[連絡先]　UBG001@pnet.energia.co.jp',
  'workmenu.btn.new': '新規申込はこちらをクリック',
  'workmenu.message.notice': '防護管取付に係る流れについては右上のアイコンからヘルプを参照してください',
  'workmenu.title.list': '申込一覧',
  'workmenu.title.number': '番号',
  'workmenu.title.date': '申込日',
  'workmenu.title.subject': '件名',
  'workmenu.title.status': 'ステータス',
  'workmenu.title.history': '過去取付履歴',
  'workmenu.title.address': '住所',

  'createUser.title': '新規ユーザー登録',
  'createUserConfirm.title': '新規ユーザー登録確認',
  'menuPage.title': '取付申込メニュー',
  'menuPage.essential.title': '次の事項は、防護管等取付に係る重要事項ですので、必ず確認していただきますようお願いいたします。',

  'confAuth.title': '確認コード入力',
  'helpPage.title': 'ヘルプ',
  'importantPage.title': "重要事項説明",
  'importantPage.message.confirm': "事前確認いただきたい事項",
  'importantPage.contact': "[内容に関するお問合せ先]",
  'importantPage.name': "　電力サポート中国 防護管受付センター",
  'importantPage.tell': "　(電話) 0120-****-****",

  'userPolicy.title': '新規ユーザー登録(利用規約)',
  //'userPolicy.btn.agree': '同意して登録する',
  'userPolicy.btn.agree': '同意します',
  'userPolicy.btn.disagree': '同意しない(ログイン画面に戻る)',
  'userPolicy.content': '○○○-ja.html',

  'message.sample.context': '確認ボタンをクリックして、ログインを行います',
  'message.button.ok': 'はい',
  'message.button.cancel': 'いいえ',
  'message.button.close': '閉じる',

  'system.message.error.title': 'システムエラー',
  'system.message.error.context': '予期せぬエラーが発生しました。\nログイン画面から再度やり直してください。',

  'message.error.403': 'セッションの有効期限が切れました。\n再度ログインしてください。',
  'message.error.429': 'アクセスが集中しております。少し時間をおいて再度アクセスをお願いします。',
  'message.error.syserror': 'システムエラーが発生しました。',
  'message.error.90000': '入力値が不正です。',
  'message.usage.nodata': 'お客様の情報はまだ反映されておりません(反映までに数日かかることがあります)',
  'message.info.nodata': '取付の申込みはありません',
  'message.banktransfer.nodata': 'ご利用できる金融機関がありません',
  'message.credittransfer.nodata': 'ご利用できるクレジット会社がありません',
// validationチェックメッセージ
  'inputCheckMessage.required': '必須入力です',
  'inputCheckMessage.required2': '携帯電話番号か電話番号のどちらかは必須です',
  'inputCheckMessage.required3': 'いずれかを選択してください',
  'inputCheckMessage.min': '{min}文字以上で入力してください',
  'inputCheckMessage.max': '{max}文字以内で入力してください',
  'inputCheckMessage.mailaddress': 'メールアドレスの形式が正しくありません',
  'inputCheckMessage.password': 'パスワードは半角英字大文字、半角英字小文字、半角数字をそれぞれ含む必要があります',
  'inputCheckMessage.passwordConfirm': 'パスワードが一致しません',
  'inputCheckMessage.number': '数字で入力してください',
  'inputCheckMessage.fullchar': '全角文字で入力してください',
  'inputCheckMessage.fullKanachar': '全角カタカナで入力してください',
  'inputCheckMessage.pastdays': '過去の日付は指定できません',
  'inputCheckMessage.wrongday': '開始日より過去の日付は指定できません',
  'inputCheckMessage.telephone': '0(ゼロ)始まりで、半角の数字とハイフンを含む必要があります',
  'inputCheckMessage.required.range': '少なくとも１つは選択してください',
  'inputCheckMessage.required.upload': '画像かお問合せ内容のどちらかは必須です',

  'userUpdate.title': 'ユーザー情報変更',
  'UserUpdate.message.contact': '※オペレータより電話連絡させていただく場合がありますので、平日の日中連絡の取れる連絡先をご記入ください',
  'userUpdate.companynameKanji': '会社名(漢字)',
  'userUpdate.companyname': '会社名(全角カナ)',
  'userUpdate.usernameFamily': '申込者名(姓)(漢字)',
  'userUpdate.usernameFirst': '申込者名(名)(漢字)',
  'userUpdate.usernameFamilyKana': '申込者名(姓)(全角カナ)',
  'userUpdate.usernameFirstKana': '申込者名(名)(全角カナ)',
  'userUpdate.zipCode': '会社所在地(郵便番号)',
  'userUpdate.companyaddress': '会社所在地(住所)',
  'userUpdate.mobile': '携帯電話番号',
  'userUpdate.telephone': '電話番号',
  'userUpdate.oldPassword':'現在のパスワード',
  'userUpdate.password': '新しいパスワード',
  'userUpdate.password2': '新しいパスワード(確認)',
  'userUpdate.telephoneinfo': '※オペレータより電話連絡させていただく場合がありますので、平日の日中連絡の取れる連絡先をご記入ください',
  'userUpdate.password.info': '※パスワードは半角英字大文字、半角英字小文字、半角数字を含めて8文字以上32文字以内で入力してください',
  'userUpdate.chk.updatePassword': 'パスワードを変更する',
  'userUpdate.btn.search': '検索',
  'userUpdate.btn.update': '変更',
  'userUpdate.dialog.error.nondata': '住所が取得できませんでした。',
  'userUpdate.dialog.inquiry': 'ユーザー情報を変更します。よろしいですか？',
  'userUpdate.dialog.finish': 'ユーザー情報の変更が完了しました。',
  'userUpdate.dialog.error.failure': 'ユーザー情報の変更に失敗しました。',
  'userUpdate.dialog.error.failure.password': 'パスワードの変更に失敗しました。再度やり直して下さい。',
  'userUpdate.dialog.error.system': 'システムエラーが発生しました。',

  'btn.addressSearch': '住所検索',
  'btn.Search': '検索',
  'btn.Send': '登録',
  'upload.description': '説明',
  'upload.error.title': 'エラー',
  'upload.error.sizeover': 'ファイルのサイズが上限を超えています',
  'upload.error.typepermission': '許可されていないファイル形式です',

  'title': '',
  'context': '',
};
