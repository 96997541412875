import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Upload from '../../organisms/UploadCustom';
import UserHeader from '../../organisms/UserHeader';
import Paper from '@material-ui/core/Paper';
import * as validateRule from '../../validateRule';
import Grid from '@material-ui/core/Grid';
import Conf from '../../../config/config.js';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 4px 16px 4px',
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    textAlign: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  card: {
    margin: '16px 16px 40px 16px',
    textAlign: 'center',
  },
  cardTop: {
    margin: '0 16px 16px 16px',
  },
  typography:{
    margin: '0 10px 0px 20px'
  },
  typography2:{
    margin: '50px 20px 0 20px'
  },
  typographySample: {
    border: "solid 1px #d50000",
    color: "#d50000",
    margin: '6px 0 0 10px',
    padding: '5px 0',
    borderRadius: "0.1em",
    fontSize: "0.9em",
    width: "190px",
    textAlign: "center",
  },
  typographySec: {
    color: "#d50000",
    border: "solid 1px #d50000",
    margin: '0 10px 0 10px',
    padding: '0 0 0 5px',
    borderRadius: "0.1em",
    fontSize: "0.8em",
  },
  textField: {
    margin: '30px 16px 10px 16px',
  },
  button: {
    marginTop: '20px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  button2: {
    marginTop: '20px',
    width: '80%',
    textAlign: 'center',
    maxWidth: '300px',
  },
  upload: {
    margin: '10px 20px 0px 20px',
    alignItems: 'center',
  },
  paper: {
    padding: '4px',
    margin: '0px 6px 4px 6px',
    overflow: 'auto',
    maxHeight: 220,
    border: '#aaaaaa solid 1px',
  },
  cell: {

  }
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error, valid },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    {...input}
    {...custom}
  />
);

renderTextField.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

class WorkInquiry extends React.Component {
  state = {
    ImageDatas: [],
    errFlg: false,
    comment: ""
  };

  setFiles = (e) => {
    this.setState({ImageDatas: e});
    if (e.length > 0) {
      this.setState({errFlg: false});
    }else{
      if (this.state.comment.length === 0) {
        this.setState({errFlg: true});
      }
    }
  }

  changeComment = (e) => {
    this.setState({comment: e.target.value});
    if (e.target.value.length > 0) {
      this.setState({errFlg: false});
    }else{
      if (this.state.ImageDatas.length > 0) {
        this.setState({errFlg: false});
      }else{
        this.setState({errFlg: true});
      }
    }
  }

  // 申込内容確認画面に遷移
  onClickBack = () => {
    this.props.history.push('/workConsideration');
  };

  //
  doWorkInquiry = () => {
    const { doOpenDialog } = this.props;
    const { ImageDatas, comment } = this.state;

    if (ImageDatas.length === 0 && comment.length === 0) {
      this.setState({errFlg: true});
      return;
    }else{
      this.setState({errFlg: false});
    }

    const dialog = {
      type: 'okcancel', // 「確認」と「キャンセル」を表示
      title: '',
      message: 'workInquiry.dialog.inquiry',
      action: this.doAction,
    };
    doOpenDialog(dialog);
    /*
    if (!doOpenDialog(dialog)) {
      // noの場合
      return;
    }
    */
  }

  // 問合せ参照取得
  getApply = async() => {
    const { getWorkInquiryAction, doOpenDialog } = this.props;
    try {
      const result = await getWorkInquiryAction();
      if (result === "00000") {
        // 正常の場合
        // 画面再レンダリング

      }else if (result === "20900") {
        // 問合せ参照失敗だった場合
        const { doOpenDialog } = this.props;
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.failure2',
          action: this.doMoveMenu
        };
        doOpenDialog(dialog);
      }else{
        // その他エラー
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }catch (err){
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }
  }


  doAction = async() => {
    const { doWorkInquiryAction, doOpenDialog } = this.props;

    try {
      const result = await doWorkInquiryAction(this.state.ImageDatas);
      if (result === "ERROR_SIZE_OVER") {
        const dialog = {
          type: 'close', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.sizeover',
        };
        doOpenDialog(dialog);
      }
      else if (result === "00000" ) {
        // 正常
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.finish',
          action: this.onClickBack,
        };
        doOpenDialog(dialog);
      }
      else if (result === "21000") {
        // 問合せ登録失敗
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.failure',
          action: this.doMoveMenu
        };
        doOpenDialog(dialog);
      } else if (result === "21001") {
        // 問合せが更新されていた場合(問合せ該当データなし)
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.update',
          action: this.getApply
        };
        doOpenDialog(dialog);
      } else if (result === "ERROR_UPLOAD") {
        // ファイルアップロード失敗(メッセージを表示してメニュー画面表示)
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.failure.upload',
          action: this.doMoveMenu
        };
        doOpenDialog(dialog);
      } else {
        // その他エラー(有り得ないはずだけどもバリデーションエラー"90000"など)
        const dialog = {
          type: 'error', // '閉じる'のみ表示
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }

    }
    catch (err) {
      // その他エラー
      const dialog = {
        type: 'error', // '閉じる'のみ表示
        title: 'system.message.error.title',
        message: 'system.message.error.context',
      };
      doOpenDialog(dialog);
    }
  };

  // メニュー画面に遷移
  doMoveMenu = () => {
    this.props.history.push('/workMenu');
  };

  render() {
    const { classes, handleSubmit, applyDetail, inquiryList, doOpenDialog } = this.props;
    if (inquiryList) {
      if ( inquiryList.errorCode === '20900') {
        // 問合せ参照失敗だった場合
        const dialog = {
          type: 'ok', // '閉じる'のみ表示
          title: '',
          message: 'workInquiry.dialog.error.failure2',
          action: this.doMoveMenu()
        };
        doOpenDialog(dialog);
      } else if ( inquiryList.errorCode !== '00000') {
      // その他エラーだった場合
        const dialog = {
          type: 'error', // '閉じる'のみ表示（ログイン画面へ）
          title: 'system.message.error.title',
          message: 'system.message.error.context',
        };
        doOpenDialog(dialog);
      }
    }

    return (
      <div className={classes.root}>
        <UserHeader />

        <Typography variant="h5" className={classes.typography}><FormattedMessage id={'workInquiry.subject'}/>{applyDetail.data.OrderName__c}</Typography>
        <br/><br/>
        <form noValidate autoComplete="off" className={classes.root}>
        <Typography variant="subtitle1" className={classes.typography}><FormattedMessage id={'workInquiry.upload.image'}/><font size="2"><FormattedMessage id={'workInquiry.upload.caution'}/></font></Typography>

        <Typography variant="subtitle2" display="inline" className={classes.typographySec}>
          <FormattedMessage id={'photo.upload.message.picture'}/><br />
          <FormattedMessage id={'photo.upload.message.require1'}/><br />
          <FormattedMessage id={'photo.upload.message.require2'}/>
        </Typography>

        <Typography variant="h5" className={classes.typographySample}>
          <FormattedMessage id={'photo.upload.sample'}/>
          <a target="_blank" rel="noopener noreferrer" href={Conf.SAMPLE_PHOTO_URL}>
            <FormattedMessage id={'photo.upload.sample.here'}/>
          </a>
        </Typography>

        <Typography variant="subtitle1" className={classes.typography}>
          <Upload
            maxFileCount={10}
            maxFileSize={5242880}
            previewFlag={true}
            previewWidth="35%"
            acceptFileType=".jpg,.jpeg,.png,.gif,.pdf"
            fileSetHandler={this.setFiles}
            />
        </Typography>
        <Field
          id="comment"
          name="comment"
          component={renderTextField}
          label=<FormattedMessage id={'workInquiry.in.inqualy'}/>
          margin="normal"
          variant="outlined"
//          inputProps={{ inputMode: 'text' }}
          type="text"
          multiline={true}
          onChange={this.changeComment}
          rows={3}
          validate={[
              validateRule.maxLength(1000),
          ]}
        />
{this.state.errFlg &&
// エラーメッセージ
        <div style={{marginBottom:"10px"}}>
          <Typography variant="subtitle2"
            style={{display:"inline-block", margin:"0 0 -10px 0", fontSize:"0.75em", color:"red"}}>
            <FormattedMessage id={'inputCheckMessage.required.upload'}/><br />
          </Typography>
        </div>
}
        </form>

      {(inquiryList && inquiryList.errorCode === "00000" && inquiryList.data && inquiryList.data.ContactHistory__c.length > 0) &&
        <React.Fragment>
          <Typography variant="subtitle1" className={classes.typography}><FormattedMessage id={'workInquiry.inquiry.history'}/></Typography>
          <Paper variant="outlined" className={classes.paper}>
            {inquiryList.data.ContactHistory__c.split("\n").map((row, index) => (
            <Typography variant="subtitle2" key={index}>
              {row}
            </Typography>
            ))}
          </Paper>
        </React.Fragment>
      }
       <div className={classes.root2}>
       <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button2}
          size="large"
          onClick={handleSubmit(this.doWorkInquiry)}
          disabled={ (applyDetail && applyDetail.data.StatusOperator__c === "撤去済") || (applyDetail && applyDetail.data.StatusOperator__c === "取消") ? true : false}
        >
          <span><FormattedMessage id={'common.btn.send'}/></span>
        </Button>
       </Grid>
        <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button2}
          size="large"
          onClick={this.onClickBack}
        >
          <span><FormattedMessage id={'common.btn.back'}/></span>
        </Button>
        </Grid>
        </div>
      </div>
    );
  }
}

const FORM_NAME = "WorkInquiry";

WorkInquiry.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = state => ({
  initialValues: {
        comment:null,
  },
});

WorkInquiry = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  //  validate,
  enableReinitialize: true,
})(connect(state => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(WorkInquiry));

export default withStyles(styles)(
  connect(
    mapStateToProps
  )(WorkInquiry)
);
